'use client'
import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg'
import { useUploadResumeContext, withUploadResumeProvider } from './context'

import MobileModal from '../Common/MobileModal'
import Main from './Main'
import Progress from './Progress'
import Success from './Success'
import Failed from './Failed'

export const UploadResumeModal = (props: {
  lang: string
  open: boolean
  onCancel: (value: boolean) => void
  onOk: (data: any) => void
  userDetail: any
  getUserInfo: () => Promise<any>
}) => {
  const { open, onOk, onCancel, getUserInfo } = props
  const isMobile = isMobileDevice()

  const { step, setStep, setProgress, cancelTokenSource, setUserData } = useUploadResumeContext()

  useEffect(() => {
    setUserData(props.userDetail)
  }, [props.userDetail])

  const handleOk = () => {
    setStep(1)
    setProgress(0)
    onOk && onOk(false)
  }

  const handleCancel = () => {
    setStep(1)
    setProgress(0)
    getUserInfo && getUserInfo()
    cancelTokenSource && cancelTokenSource.cancel('User canceled the upload')
    onCancel && onCancel(false)
  }

  const renderContent = () => {
    return (
      <>
        {step == 1 && <Main {...props} />}
        {step == 2 && <Progress {...props} />}
        {step == 3 && <Success {...props} />}
        {step == 4 && <Failed {...props} />}
      </>
    )
  }

  if (isMobile) {
    return (
      <MobileModal onClose={handleCancel} open={open} onCancel={onCancel}>
        {renderContent()}
      </MobileModal>
    )
  }

  return (
    <Modal
      open={open}
      onOk={handleOk}
      width={420}
      zIndex={2002}
      onCancel={handleCancel}
      wrapClassName='initWrapModal'
      maskClosable={false}
      closeIcon={<CloseIcon />}
      title={null}
      footer={null}
      // styles={{
      //   body: {
      //     padding: '28px 12px 40px 12px',
      //   }
      // }}
    >
      {renderContent()}
    </Modal>
  )
}

export default withUploadResumeProvider(UploadResumeModal)
