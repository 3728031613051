import React from 'react'
import styles from '../../common.module.scss'
import dynamic from 'next/dynamic'

import ChangeLanguage from './ChangeLang'
import linkToHunt from 'helpers/linkToHunt'
import Link from 'next/link'
const MaintainNotification = dynamic(() => import('components/MaintainNotification'), {
  ssr: false
})
import { LogoTextIcon } from './icons'

export const CommonHeader = (props) => {
  const { lang, langKey } = props
  return (
    <div className={styles.header}>
      <MaintainNotification lang={props.lang?.header} langKey={langKey} />
      <div className={styles.headerContainer}>
        <div className={styles.logo}>
          <Link href={`/${langKey}`}>
            <LogoTextIcon />
          </Link>
        </div>
        <div className={styles.headerRight}>
          <Link href={linkToHunt('boss')} className={styles.link}>
            {lang?.profile?.ImHiring}
          </Link>
          <ChangeLanguage />
        </div>
      </div>
    </div>
  )
}
