import React, { useEffect, useMemo, useState } from 'react'
import { Avatar, message } from 'antd'
import classNames from 'classnames'

import { avatarList } from '../../images'
import { getImageBlob } from '../utils'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import styles from './index.module.scss'

const maxSize = 10 * 1000 * 1000

const ChangeAvatar = (props: {
  onOk: (file: any, index?: number) => void
  onChange: (file: any, index?: number) => void
  lang?: any
  selectAvatarIndex: any
}) => {
  const { onOk, lang, onChange, selectAvatarIndex } = props
  const profile = lang.profile || {}
  const [selected, setSelected] = useState(selectAvatarIndex || undefined)

  useEffect(() => {
    if (selectAvatarIndex) {
      setSelected(selectAvatarIndex)
    }
  }, [selectAvatarIndex])

  const disabledSubmit = useMemo(() => {
    return selected === undefined
  }, [selected])

  const handleOk = async () => {
    const current = avatarList[selected - 1]
    if (!current) return
    const fileBlob = await getImageBlob(current.src)
    onOk && onOk(fileBlob, selected)
  }

  return (
    <div className={styles.changeAvatar}>
      <div className={styles.changeAvatar_container}>
        <div className={styles.changeAvatar_title}>
          <p className={styles.changeAvatar_subtitle}>{profile.avatarModal?.title}</p>
        </div>

        <div className={styles.changeAvatar_desc}>
          <p>{profile.avatarModal?.tip}</p>
        </div>

        <div className={styles.changeAvatar_upload}>
          <button
            className={styles.changeAvatar_upload_btn}
            onClick={(e) => {
              const target = e.currentTarget as HTMLButtonElement
              target?.querySelector('input')?.click()
            }}
          >
            <div className={styles.changeAvatar_upload_main}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M12.8758 8.77661L10.043 11.606L10.9852 12.5494L12.2205 11.3156L12.2205 14.7342H13.5538L13.5538 11.3236L14.7747 12.5319L15.7127 11.5843L12.8758 8.77661Z" fill="#2378E5" />
                <path d="M14.0286 7.93896C13.8683 5.7761 12.0627 4.07113 9.85885 4.07113C7.73861 4.07113 5.98509 5.65043 5.71403 7.69628L5.59348 8.60608L4.70062 8.81834C3.71653 9.05228 2.98568 9.93882 2.98568 10.9928C2.98568 12.2271 3.9863 13.2277 5.22064 13.2277H9.55859V14.5611H5.22064C3.24993 14.5611 1.65234 12.9635 1.65234 10.9928C1.65234 9.30726 2.82101 7.89468 4.39225 7.52115C4.74998 4.82118 7.06111 2.73779 9.85885 2.73779C12.7993 2.73779 15.2022 5.03916 15.3648 7.93896H14.0286Z" fill="#2378E5" />
              </svg>
              {profile.avatarModal?.uploadText} */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='32'
                height='26'
                viewBox='0 0 32 26'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M29.6114 25.5403H2.38863C1.75512 25.5403 1.14757 25.2886 0.699614 24.8407C0.251659 24.3927 0 23.7852 0 23.1517V6.43128C0 5.79778 0.251659 5.19022 0.699614 4.74227C1.14757 4.29431 1.75512 4.04266 2.38863 4.04266H7.16588L9.5545 0.459717H22.4455L24.8341 4.04266H29.6114C30.2449 4.04266 30.8524 4.29431 31.3004 4.74227C31.7483 5.19022 32 5.79778 32 6.43128V23.1517C32 23.7852 31.7483 24.3927 31.3004 24.8407C30.8524 25.2886 30.2449 25.5403 29.6114 25.5403ZM20.4604 14.0254C20.4604 11.5624 18.4637 9.56566 16.0006 9.56566C13.5375 9.56566 11.5408 11.5624 11.5408 14.0254C11.5408 16.4885 13.5375 18.4852 16.0006 18.4852C18.4637 18.4852 20.4604 16.4885 20.4604 14.0254ZM16.0006 7.73642C19.4739 7.73642 22.2896 10.5521 22.2896 14.0254C22.2896 17.4988 19.4739 20.3145 16.0006 20.3145C12.5273 20.3145 9.71156 17.4988 9.71156 14.0254C9.71156 10.5521 12.5273 7.73642 16.0006 7.73642Z'
                  fill='black'
                />
              </svg>
            </div>
            <input
              type='file'
              accept='image/*'
              hidden
              style={{ display: 'none' }}
              onChange={(e) => {
                const file = e.target?.files?.[0]
                if (!file) {
                  return
                }
                if (file.size > maxSize) {
                  message.error(profile.avatarModal?.errorTip)
                } else {
                  onChange && onChange(file)
                }
                e.target.value = ''
              }}
            />
          </button>

          <div className={styles.changeAvatar_upload_tips}>{profile.avatarModal?.uploadTip}</div>
        </div>

        <div className={styles.changeAvatar_avatar}>
          <span className={styles.changeAvatar_or}>{profile.avatarModal?.or}</span>
          <div className={styles.changeAvatar_avatar_list}>
            {avatarList.map((item, index) => {
              return (
                <div
                  onClick={() => setSelected(index + 1)}
                  key={index}
                  className={
                    styles.changeAvatar_avatar_item +
                    ' ' +
                    (selected == index + 1 ? styles.changeAvatar_avatar_item_selected : '')
                  }
                >
                  <Avatar className={styles.changeAvatar_avatar_img} src={item.src} alt='avatar' />
                  {selected == index + 1 && (
                    <CheckedIcon className={styles.changeAvatar_avatar_checked} />
                  )}
                </div>
              )
            })}
          </div>
        </div>

        <div className={styles.changeAvatar_footer}>
          <Button
            btnSize='size48'
            className={classNames(
              styles.changeAvatar_confirm,
              disabledSubmit && styles.changeAvatar_confirm_disabled
            )}
            disabled={disabledSubmit}
            onClick={handleOk}
          >
            {profile.confirm}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ChangeAvatar

const CheckedIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <rect width={16} height={16} fill='#2378E5' rx={8} />
    <path stroke='#fff' strokeWidth={1.158} d='m4.254 8.271 2.46 2.316 5.031-5.175' />
  </svg>
)
