import avatar1 from './1.png'
import avatar2 from './2.png'
import avatar3 from './3.png'
import avatar4 from './4.png'
import avatar5 from './5.png'

export const avatarList = [avatar1, avatar2, avatar3, avatar4, avatar5]

export {
  avatar1, avatar2, avatar3, avatar4, avatar5
}