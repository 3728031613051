import React from 'react'
import styles from '../../common.module.scss'

import ChangeLanguage from './ChangeLang'
import linkToHunt from 'helpers/linkToHunt'
import Link from 'next/link'

import { LogoIcon } from './icons'

const DesiredHeader = (props) => {
  const { lang, langKey } = props
  return (
    <div className={styles.desiredHeader}>
      <div className={styles.desiredHeaderContainer}>
        <div className={styles.desiredHeaderLogo}>
          <Link href={`/${langKey}`}>
            <LogoIcon />
          </Link>
        </div>
        <div className={styles.desiredHeaderRight}>
          <Link href={linkToHunt('boss')} className={styles.desiredHeaderHiring}>
            {lang?.profile?.ImHiring}
          </Link>
          <ChangeLanguage />
        </div>
      </div>
    </div>
  )
}

export default DesiredHeader
