import React, { useRef } from 'react'
import styled from '@emotion/styled'
import { message } from 'antd'
import { ReactComponent as ResumeFailedIcon } from '../../images/svg/resume_failed.svg'
import { maxFileSize, useUploadResumeContext } from './context'
import UploadResumeWrap from './Wrap'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
const UploadFailed = (props: { lang: any; userDetail: any; getUserInfo: () => Promise<any> }) => {
  const { lang = {}, userDetail } = props
  const profile = lang.profile || {}
  const inputRef = useRef(null)
  const { uploadResumeFile, fileData } = useUploadResumeContext()

  const onChange = (event) => {
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const resumeId = userDetail?.resume?.id || fileData?.data?.id
    if (file.size > maxFileSize) {
      message.error(profile?.uploadResumeModal?.errorTip)
      return
    }
    uploadResumeFile({ file, resumeId: resumeId })
  }

  const handleUpload = () => {
    inputRef.current.click()
  }

  return (
    <UploadResumeWrap
      {...props}
      title={<div>{profile?.uploadResumeModal?.uploadFailed}</div>}
      footer={
        <Button btnSize='size48' style={{ width: '100%', margin: '32px 0 8px  0' }}>
          <div>
            <div className='upload-failed-desc'>
              {/* {profile?.uploadResumeModal?.uploadFailedTip}{' '} */}
              <span className='re-upload' onClick={handleUpload}>
                {profile?.uploadResumeModal?.reUploadClick}
              </span>
            </div>
            <input
              accept='.pdf, .doc, .docx'
              type='file'
              ref={inputRef}
              onChange={onChange}
              style={{ display: 'none' }}
              hidden
            />
          </div>
        </Button>
      }
    >
      <ContentWrap>
        <div className='file-info'>
          {FiledSvg}
          {/* <p className='upload-failed'>{profile?.uploadResumeModal?.uploadFailed}</p> */}
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  )
}
const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`
}

const ContentWrap = styled.div`
  .file-info {
    display: flex;
    padding: 60px 24px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    ${media.msd} {
      padding: 48px 0 0 0;
    }
    .upload-failed {
      margin-top: 26px;
      color: #121212;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
    }

    .upload-failed-desc {
      margin-top: 20px;
      color: #7d7d7d;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }

    .re-upload {
      color: #2378e5;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export default UploadFailed

const FiledSvg = (
  <svg width='64' height='65' viewBox='0 0 64 65' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_845_112657)'>
      <path
        d='M49.7301 8.35854C48.9168 7.54427 47.7891 7.07654 46.6362 7.07654H23.7335C23.441 7.07654 23.1616 7.19272 22.9542 7.39904L13.1389 17.2144C12.9326 17.4207 12.8164 17.7011 12.8164 17.9936V53.9828C12.8164 56.3946 14.7785 58.3567 17.1902 58.3567H46.6362C47.7891 58.3567 48.9168 57.8889 49.7301 57.0757C50.5554 56.2504 51.0101 55.1527 51.0101 53.9838V11.4504C51.0101 10.2815 50.5554 9.18283 49.7311 8.35854H49.7301ZM24.1902 9.27998H24.2683V16.358C24.2683 17.5549 23.2948 18.5284 22.0979 18.5284H15.0198V18.4503L24.1902 9.27998ZM48.8066 53.9828C48.8066 54.5627 48.5813 55.1076 48.1716 55.5172C47.768 55.9209 47.2091 56.1532 46.6362 56.1532H17.1902C15.9934 56.1532 15.0198 55.1797 15.0198 53.9828V20.7309H22.0979C24.5097 20.7309 26.4717 18.7688 26.4717 16.357V9.27998H46.6362C47.2081 9.27998 47.768 9.51134 48.1716 9.91597C48.5803 10.3246 48.8056 10.8695 48.8056 11.4504V53.9828H48.8066Z'
        fill='black'
      />
      <rect x='22.6641' y='31.9999' width='18.6667' height='2.26667' rx='1.13333' fill='black' />
      <rect x='22.6641' y='38.6665' width='10.6667' height='2.26667' rx='1.13333' fill='#0066FF' />
    </g>
    <circle cx='48.4679' cy='52.446' r='8.33903' fill='white' />
    <path
      d='M47.6415 60.4263C53.2295 60.4263 57.7595 55.8963 57.7595 50.3082C57.7595 44.7202 53.2295 40.1902 47.6415 40.1902C42.0534 40.1902 37.5234 44.7202 37.5234 50.3082C37.5234 55.8963 42.0534 60.4263 47.6415 60.4263ZM52.1074 47.2728L49.0725 50.3077L52.1088 53.3441L50.6779 54.775L47.6416 51.7387L44.6052 54.775L43.1743 53.3441L46.2107 50.3077L43.1757 47.2728L44.6066 45.8419L47.6416 48.8768L50.6765 45.8419L52.1074 47.2728Z'
      fill='#FE4A4B'
    />
    <defs>
      <clipPath id='clip0_845_112657'>
        <rect
          width='38.1927'
          height='51.2801'
          fill='white'
          transform='translate(12.8164 7.07654)'
        />
      </clipPath>
    </defs>
  </svg>
)
