import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
// import SimpleSteps from '../Step/Simple'
import SafeInfo from '../Common/SafeInfo'
import { jumpPage } from '../utils'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import ResumeSuccessIcon from '../../images/success-mobile.png'

const CompleteProfileH5 = (props: { lang: any }) => {
  const { lang } = props
  const profile = lang.profile || {}
  const [countdown, setCountdown] = useState(3)

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => (prev > 0 ? prev - 1 : 0))
    }, 1000)

    if (countdown === 0) {
      jumpPage && jumpPage()
      clearInterval(timer)
    }

    return () => clearInterval(timer)
  }, [countdown])

  const handleBack = () => {
    jumpPage && jumpPage()
  }

  return (
    <>
      <CompleteProfileH5Wrap>
        <div>
          <div className='profileWrap'>
            <h1>{profile.completeProfile?.title} 🎉</h1>
            <p>{profile.completeProfile?.subtitle}</p>
            <div className='successContent'>
              <img src={ResumeSuccessIcon.src} alt='' />
            </div>
          </div>
          {/* <div className='safeInfoWrap'>
            <SafeInfo lang={lang} />
          </div> */}

          <div className='backBtnWrapFixed'></div>
        </div>
        <div className='backBtnWrap'>
          <Button className='backBtn' btnSize='size48' variant='primaryBtn' onClick={handleBack}>
            {profile.completeProfile?.button} ({countdown})
          </Button>
        </div>
      </CompleteProfileH5Wrap>
    </>
  )
}

const CompleteProfileH5Wrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: #fff;

  .profileWrap {
    display: flex;
    padding: 32px 20px;
    flex-direction: column;
    align-items: center;
  }

  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    color: #000;
  }

  p {
    color: #3b3c3c;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    padding: 0 20px;
    margin-top: 16px;
  }

  .successContent {
    width: 100%;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    text-align: center;

    img {
      margin-top: 32px;
      width: 230px;
      height: 227px;
    }
  }

  .backBtnWrap {
    padding: 10px 20px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 12;
  }

  .backBtnWrapFixed {
    height: 80px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
  }

  .backBtn {
    width: 100%;
    cursor: pointer;
  }

  .safeInfoWrap {
    padding: 0 20px;
    margin-top: 20px;
  }
`

export default CompleteProfileH5
