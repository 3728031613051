'use client'
import { Modal } from 'antd'
import React, { useContext } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { LinkContext } from 'app/components/providers/linkProvider'
import { usePathname } from 'next/navigation'
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg'

import EducationExperience from './EducationExperience'
import WorkExperience from './WorkExperience'
import { useAttachedResumeContext } from '../AttachedResume/context'
import MobileModal from '../Common/MobileModal'
import { getUrlSearchString } from '../utils'

import styles from '../../common.module.scss'

export const ResumeOnlineModal = (props: {
  lang: any
  open: boolean
  onCancel: (data: any) => void
  onOk: (data: any) => void
  config: any
  schoolList: any[]
  userDetail: any
  getUserInfo: () => Promise<any>
}) => {
  const { lang, open, onOk, onCancel } = props
  const isMobile = isMobileDevice()
  const { step, setStep } = useAttachedResumeContext()
  const { push } = useContext(LinkContext)
  const pathname = usePathname()

  const handleOk = () => {
    onOk && onOk(false)
  }

  const handleCancel = () => {
    onCancel && onCancel(false)
  }

  const handleExpOk = () => {
    setStep(2)
  }

  const handleExpCancel = () => {
    onCancel && onCancel(false)
  }

  const handleWorkOk = () => {
    const searchWords = getUrlSearchString(1)
    push(`${pathname}/thank-you/${searchWords}`)
  }

  const handleWorkCancel = () => {
    setStep(1)
  }

  const renderContent = () => {
    if (step == 2) {
      return <WorkExperience {...props} onOk={handleWorkOk} onCancel={handleWorkCancel} />
    }
    return <EducationExperience {...props} onOk={handleExpOk} onCancel={handleExpCancel} />
  }

  if (isMobile) {
    return (
      <MobileModal onClose={handleCancel} open={open} onCancel={onCancel}>
        {renderContent()}
      </MobileModal>
    )
  }

  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ top: 30 }}
      maskClosable={false}
      title={null}
      footer={null}
      width={700}
      zIndex={2002}
      rootClassName={styles.resumeModal}
      closeIcon={<CloseIcon />}
      // styles={{
      //   body: {
      //     padding: '28px 24px 28px 24px',
      //   }
      // }}
    >
      {renderContent()}
    </Modal>
  )
}

export default ResumeOnlineModal
