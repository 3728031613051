import React from 'react'
import { Drawer } from 'antd';
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg';
import styles from '../../common.module.scss'

const MobileModal = (props: {
  open: boolean
  onCancel: any
  children: any
  onClose: any
}) => {

  const { open, onCancel, children, onClose, ...rest } = props

  return (
    <Drawer
      title={null}
      footer={null}
      placement={'bottom'}
      closable={false}
      onClose={onClose}
      open={open}
      height={'auto'}
      rootClassName={styles.mobileModal}
      // styles={{
      //   body: {
      //     padding: '40px 20px 20px 20px',
      //   }
      // }}
      {...rest}
    >
      <div className={styles.mobileModal_closed} onClick={onCancel}>
        <CloseIcon />
      </div>
      {children}
    </Drawer>
  )
}

export default MobileModal
