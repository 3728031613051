'use client'
import React, { useMemo, useRef, useState } from 'react'
import { DatePicker, Input, Switch, AutoComplete, message } from 'antd'
import { DatePickerWithToday } from '@bossjob/ui'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { removeEmptyOrNullValues } from 'helpers/formatter'
import type { SelectProps } from 'antd'

import {
  deleteBlackListCompaniesServie,
  fetchAddBlacklistCompaniesService,
  searchCompanyByDisplayName,
  updateUserCompleteProfileService
} from 'store/services/users/updateUserCompleteProfile'
import { updateUserWorkExperienceService } from 'store/services/users/updateUserWorkExperience'
import { addUserWorkExperienceService } from 'store/services/users/addUserWorkExperience'
import { generateUserResumeService } from 'store/services/users/generateUserResume'

import LoadingButton from '../Common/LoadingButton'
import SimpleSteps from '../Step/Simple'
import { useAttachedResumeContext } from '../AttachedResume/context'
import JobFunction from '../JobFunction'

import styles from './index.module.scss'
import { addEventTag, isMobileDevice } from 'helpers/utilities'
import { isChatProcess } from '../utils'
import { fetchCompanySuggestionsService } from 'store/services/companies2/fetchCompanySuggestions'
import { BackIcon } from '../Common/icons'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import OverrideAntdSwitch from 'app/(new-pages)/components/overrideAntd/OverrideSwitch'
import TextEditor from 'components/TextEditor'
// const { TextArea } = Input

const WorkExperience = (props: {
  onCancel: () => void
  onOk: (value: any) => void
  lang: any
  userDetail: any
  getUserInfo: () => Promise<any>
}) => {
  const { onCancel, onOk, lang, userDetail = {}, getUserInfo } = props
  const { workData, setWorkData } = useAttachedResumeContext()
  console.log({ workData })
  const [companyId, setCompanyId] = useState('')
  const [loading, setLoading] = useState(false)
  const { work_experiences = [], resumes } = userDetail
  const profile = lang.profile || {}
  const isMobile = isMobileDevice()
  const chatProcess = isChatProcess()
  const [companies, setCompanies] = useState<SelectProps['options']>([])
  const disabledSubmit = useMemo(() => {
    if (workData.noExp) {
      return false
    }
    const checkDate = !workData.startDate || (!workData.endDate && !workData.isToday)
    const checkJobTitle = !workData.jobTitle?.id
    const desc = stripHtmlTags(workData?.description_html)
    const checkDescription = desc && desc?.length < 10
    if (checkDescription) return true

    return !workData.companyName || checkJobTitle || checkDate
  }, [workData])

  const handleEndTime = (date, dateString, isNow) => {
    setWorkData({ ...workData, endDate: date, isToday: isNow })
  }

  const handleStartTime = (date, dateString) => {
    setWorkData({ ...workData, startDate: date, endDate: null })
  }

  const getSubmitData = () => {
    if (workData.noExp) {
      return {
        is_fresh_graduate: workData.noExp
      }
    } else {
      const data = {
        job_title: workData.jobTitle.value,
        function_job_title_id: workData.jobTitle?.id,
        company: workData.companyName,
        working_period_from: `${workData.startDate.format('YYYY-MM')}-01`,
        working_period_to: workData.isToday ? null : `${workData.endDate.format('YYYY-MM')}-01`,
        is_currently_work_here: workData.isToday,
        //   description: workData.description,
        description_html: workData.description_html
      }

      return removeEmptyOrNullValues(data)
    }
  }

  const generateResume = () => {
    if (resumes) {
      getUserInfo &&
        getUserInfo().finally(() => {
          onOk(false)
        })
    } else {
      const source = isMobile ? 'mobile_web' : 'web'
      const chanel = 'resume_register_generate'
      generateUserResumeService({ source, chanel })
        .then(() => {
          getUserInfo &&
            getUserInfo().finally(() => {
              onOk(false)
              setLoading(false)
            })
        })
        .catch(() => setLoading(false))
    }
  }

  const handleOk = async () => {
    const paramsWork = getSubmitData()
    setLoading(true)

    if (workData.noExp) {
      updateUserCompleteProfileService(paramsWork)
        .then(() => {
          generateResume()
        })
        .catch(() => {
          setLoading(false)
        })
      return
    } else {
      if (workData.hideCompany) {
        switchHideDisplayCompany()
      } else {
        switchShowDisplayCompany()
      }
    }

    if (work_experiences?.length) {
      const data = work_experiences[0]
      const paramsUpdate = {
        workExperienceId: data.id,
        workExperienceData: paramsWork
      }
      updateUserWorkExperienceService(paramsUpdate)
        .then(() => {
          addEventTag(
            chatProcess
              ? 'new_user_chat_resume_work_experience_fill_success'
              : 'new_user_complete_resume_work_experience_fill_success'
          )
          generateResume()
        })
        .catch((err) => {
          addEventTag(
            chatProcess
              ? 'new_user_chat_resume_work_experience_fill_failed'
              : 'new_user_complete_resume_work_experience_fill_failed'
          )
          setLoading(false)
        })
      // .finally(() => {
      //   setLoading(false)
      // })
    } else {
      addUserWorkExperienceService({
        workExperience: paramsWork
      })
        .then(() => {
          addEventTag(
            chatProcess
              ? 'new_user_chat_resume_work_experience_fill_success'
              : 'new_user_complete_resume_work_experience_fill_success'
          )
          generateResume()
        })
        .catch((err) => {
          addEventTag(
            chatProcess
              ? 'new_user_chat_resume_work_experience_fill_failed'
              : 'new_user_complete_resume_work_experience_fill_failed'
          )
          setLoading(false)
        })
      // .finally(() => {
      //   setLoading(false)
      // })
    }
  }

  const handleCancel = () => {
    onCancel()
  }

  const switchHideDisplayCompany = () => {
    if (!workData.companyName) {
      return false
    }
    searchCompanyByDisplayName(workData.companyName)
      .then((res) => {
        if (res?.data?.data) {
          const companyIds = res?.data?.data
          setCompanyId(companyIds)
          setWorkData({ ...workData, hideCompany: true })
          fetchAddBlacklistCompaniesService({
            company_ids: [companyIds]
          })
            .then((res) => {
              let info = profile.workExp?.blockedCompanyInfo
              message.success(info || 'Succeed')
            })
            .catch((err) => Promise.resolve(err))
            .finally(() => {})
        } else {
          setCompanyId('')
        }
      })
      .catch((err) => Promise.resolve(err))
  }

  const switchShowDisplayCompany = () => {
    if (!workData.companyName) {
      return false
    }
    if (companyId) {
      deleteBlackListCompaniesServie({ id: companyId })
        .then((res) => {
          setCompanyId('')
          message.success(res.data?.data?.message || 'success')
          setWorkData({ ...workData, hideCompany: false })
        })
        .catch((err) => Promise.resolve(err))
    }
  }

  const disabledDate = (current) => {
    return (
      current &&
      (current < dayjs(workData.startDate).subtract(1, 'day').endOf('day') || current > dayjs())
    )
  }

  const handleSearchCompany = (newValue: string) => {
    fetchCompanySuggestionsService({ query: newValue, size: 5 }).then((res) => {
      setCompanies(res?.data?.data?.items || [])
    })
    setWorkData({ ...workData, companyName: newValue })
  }

  const handleChangeCompany = (newValue: string) => {
    setWorkData({ ...workData, companyName: newValue })
  }

  const disabledDateStartDate = (current) => {
    return current && current > dayjs()
  }

  const checkDescription = useMemo(() => {
    const text = stripHtmlTags(workData.description_html)
    if (!text) return false
    if (text?.length < 10) return true
    if (text?.length > 4000) return true
    return false
  }, [workData.description_html])

  function stripHtmlTags(html) {
    return html?.replace?.(/<[^>]+>/g, '')
  }
  return (
    <div className={styles.eduExp + ' ' + 'yoloJobseekerCompletedSelect'}>
      <div className={styles.eduExp_container}>
        {isMobile ? (
          <WorkIconH5 className={styles.eduExp_icon} />
        ) : (
          <WorkIcon className={styles.eduExp_icon} />
        )}

        <div className={styles.eduExp_title}>
          <p className={styles.eduExp_subtitle}>{profile.workExp?.title}</p>
          <SimpleSteps className={styles.eduExp_steps} currentStep={2} totalSteps={2} />
        </div>

        <div className={styles.eduExp_switch} style={{ marginBottom: 32 }}>
          {/* <Switch
            value={workData.noExp}
            onChange={() => {
              setWorkData({ ...workData, noExp: !workData.noExp })
            }}
          /> */}
          <OverrideAntdSwitch
            value={workData.noExp}
            checked={workData.noExp}
            onChange={() => {
              setWorkData({ ...workData, noExp: !workData.noExp })
            }}
          />
          {profile.workExp?.noExpValue}
        </div>

        {!workData.noExp ? (
          <div className={styles.eduExp_content}>
            <div className={styles.eduExp_item}>
              <p className={styles.eduExp_label}>{profile.workExp?.companyLabel}</p>
              <div className={styles.eduExp_input}>
                {/* <Select
                    showSearch
                    value={workData.companyName}
                    placeholder={profile.workExp?.companyName}
                    style={{ width: '100%', height: 42 }}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={handleSearchCompany}
                    onChange={handleChangeCompany}
                    notFoundContent={null}
                    options={(companies || []).map((d) => ({
                      value: d,
                      label: d,
                    }))}
                  /> */}
                <AutoComplete
                  style={{ width: '100%', height: 42 }}
                  value={workData.companyName}
                  placeholder={profile.workExp?.companyName}
                  options={(companies || []).map((d) => ({
                    value: d,
                    label: d
                  }))}
                  onSelect={handleChangeCompany}
                  onSearch={handleSearchCompany}
                />
              </div>
              <div className={styles.eduExp_switch} style={{ marginTop: 16 }}>
                <OverrideAntdSwitch
                  value={workData.hideCompany}
                  checked={workData.hideCompany}
                  onChange={(val) => {
                    setWorkData({ ...workData, hideCompany: val })
                  }}
                />
                {profile.workExp?.hideCompany}
              </div>
            </div>

            <div className={styles.eduExp_item}>
              <p className={styles.eduExp_label}>{profile.workExp?.workPeriod}</p>
              <div className={styles.eduExp_date}>
                <DatePicker
                  style={{ width: '50%', height: 42 }}
                  placeholder={profile.workExp?.startDate}
                  picker='month'
                  onChange={handleStartTime}
                  value={workData.startDate}
                  inputReadOnly={true}
                  disabledDate={disabledDateStartDate}
                />
                <DatePickerWithToday
                  style={{ width: '50%', height: 42 }}
                  allowClear={true}
                  todayText={profile?.today}
                  picker='month'
                  value={workData.endDate}
                  isToday={workData.isToday}
                  placeholder={profile.workExp?.endDate}
                  disabledDate={disabledDate}
                  onChange={handleEndTime}
                />
              </div>
            </div>

            <div className={styles.eduExp_item}>
              <p className={styles.eduExp_label}>{profile.workExp?.jobTitle} </p>
              <div className={styles.eduExp_input}>
                <JobFunction
                  lang={lang}
                  value={workData.jobTitle}
                  className={styles.eduExp_jobFunction}
                  onChange={(value) => setWorkData({ ...workData, jobTitle: value })}
                />
              </div>
            </div>

            <div className={styles.eduExp_item}>
              <p className={styles.eduExp_label}>
                {profile.workExp?.descLabel}
                <span className={styles.eduExp_optional}>({profile?.optional})</span>
              </p>
              <div className={styles.eduExp_input}>
                <TextEditor
                  value={workData.description_html}
                  placeholder={profile.workExp?.description}
                  onChange={(value) =>
                    setWorkData({
                      ...workData,
                      description_html: value || ''
                    })
                  }
                />
                {/* <TextArea
                  rows={3}
                  minLength={10}
                  maxLength={4000}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={profile.workExp?.description}
                  value={workData.description}
                  status={checkDescription ? 'error' : undefined}
                  onChange={(e) => setWorkData({ ...workData, description: e.target.value })}
                /> */}
              </div>
              {checkDescription && (
                <p className={styles.eduExp_error}>{profile.workExp?.descriptionError}</p>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.eduExp_noExp}></div>
        )}

        <div className={styles.eduExp_footer}>
          {/* <button
            className={classNames(styles.eduExp_back, styles.eduExp_button)}
            onClick={handleCancel}
          >
            {profile.back}
          </button> */}
          <div className={styles.eduExp_backBtn} onClick={handleCancel}>
            <BackIcon />
            {profile.back}
          </div>
          {/* <LoadingButton
            className={classNames(styles.eduExp_continue, styles.eduExp_button)}
            loading={loading}
            disabledSubmit={disabledSubmit || loading}
            onSubmit={handleOk}
            text={profile.save}
          /> */}
          <Button
            loading={loading}
            disabled={disabledSubmit || loading}
            onClick={handleOk}
            variant='primaryBtn'
            btnSize='size44'
          >
            {profile.save}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default WorkExperience

const WorkIconH5 = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} fill='none' {...props}>
    <path
      fill='#121212'
      d='M22.667 5.335V8h2.667a4 4 0 0 1 4 4v13.334a4 4 0 0 1-4 4H6.667a4 4 0 0 1-4-4V12a4 4 0 0 1 4-4h2.667V5.335A2.667 2.667 0 0 1 12 2.668h8a2.667 2.667 0 0 1 2.667 2.667Zm-10.667 8H9.334v-2.667H6.667c-.736 0-1.333.597-1.333 1.333v6.667h21.333v-6.667c0-.736-.597-1.333-1.333-1.333h-2.667v2.667H20v-2.667h-8v2.667Zm14.667 8H5.334v4c0 .736.597 1.333 1.333 1.333h18.667c.736 0 1.333-.597 1.333-1.333v-4ZM20 8V5.335h-8V8h8Z'
    />
  </svg>
)

const WorkIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={37} height={37} fill='none' {...props}>
    <path
      fill='#121212'
      d='M26.28 6.092v3h3a4.5 4.5 0 0 1 4.5 4.5v15a4.5 4.5 0 0 1-4.5 4.5h-21a4.5 4.5 0 0 1-4.5-4.5v-15a4.5 4.5 0 0 1 4.5-4.5h3v-3a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3Zm-12 9h-3v-3h-3a1.5 1.5 0 0 0-1.5 1.5v7.5h24v-7.5a1.5 1.5 0 0 0-1.5-1.5h-3v3h-3v-3h-9v3Zm16.5 9h-24v4.5a1.5 1.5 0 0 0 1.5 1.5h21a1.5 1.5 0 0 0 1.5-1.5v-4.5Zm-7.5-15v-3h-9v3h9Z'
    />
  </svg>
)
