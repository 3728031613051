'use client'
import { useRouter } from 'next/navigation'
import React, { createContext, useTransition, useCallback } from 'react'
import { BrowserRouter, useNavigate } from 'react-router-dom'
export const RouterContext = createContext({
  loading: false,
  push: (a) => a,
  refresh: () => undefined
})
const Provider = RouterContext.Provider

const RoProvider = ({ children }: any) => {
  const router = useRouter()
  const [loading, startTransition] = useTransition()
  const naviagate = useNavigate()
  const push = (url) => {
    naviagate(url)
  }
  const refresh = useCallback(() => {
    startTransition(() => {
      router.refresh()
    })
  }, [router, startTransition])
  return <Provider value={{ loading: false, push, refresh }}>{children}</Provider>
}

const RouterProvider = (props) => {
  const { children, ...others } = props
  return (
    <BrowserRouter>
      <RoProvider {...others}>{children}</RoProvider>
    </BrowserRouter>
  )
}
export default RouterProvider
