import React from 'react'
import { formatTemplateString } from 'helpers/formatter'
import styles from '../../common.module.scss'

export const SafeInfo = (props) => {
  const { lang, className = '' } = props

  const { securityGuideLink, guideLink } = lang?.profile || {}

  return (
    <div className={styles.safeInfo + ' ' + className}>
      <SafeIcon className={styles.safeInfo_icon} />
      <span
        dangerouslySetInnerHTML={{
          __html: formatTemplateString(securityGuideLink, {
            guideLink: `<a
              href="https://bossjob.crisp.help/en/article/bossjob-user-data-guide-19m0q4y/"
              target="_blank"
              >${formatTemplateString(guideLink, {})}</a>`
          })
        }}
      />
    </div>
  )
}

export default SafeInfo

const SafeIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={15} height={14} fill='none' {...props}>
    <path
      fill='#86909C'
      d='M6.842 9.06a.58.58 0 0 0 .007 0h-.012a.65.65 0 0 0 .005 0ZM6.62 9.012c.07.03.145.046.222.047a.583.583 0 0 0 .42-.192l3.268-3.664-.875-.775-2.841 3.185L5.239 5.95l-.823.8L6.43 8.877c.054.057.12.103.191.134Z'
    />
    <path
      fill='#86909C'
      d='M6.977 13.33a.583.583 0 0 0 .304.087.583.583 0 0 0 .303-.088c.1-.058 2.357-1.458 3.144-2.041a6.196 6.196 0 0 0 1.814-2.112c.167-.339.262-.708.28-1.085v-5.35a.583.583 0 0 0-.408-.583L7.456.583a.513.513 0 0 0-.35 0L2.147 2.158a.583.583 0 0 0-.408.584v5.366c.02.372.115.735.28 1.068a6.236 6.236 0 0 0 1.814 2.112c.81.606 3.045 1.983 3.144 2.041ZM2.906 8.09V3.168L7.28 1.779l4.375 1.389v4.888a1.711 1.711 0 0 1-.175.618 4.982 4.982 0 0 1-1.459 1.669c-.577.443-2.094 1.388-2.741 1.79-.648-.402-2.159-1.347-2.742-1.79A5.04 5.04 0 0 1 3.08 8.674a1.75 1.75 0 0 1-.175-.583Z'
    />
  </svg>
)
