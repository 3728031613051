'use client'
import React, { useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'

import { usePathname } from 'next/navigation'
import { addEventTag, isMobileDevice } from 'helpers/utilities'

import { useAttachedResumeContext, withAttachedResumeProvider } from './context'
import { useSchool } from './hooks'
import { getUrlSearchString, isChatProcess } from '../utils'

import SafeInfo from '../Common/SafeInfo'
import AttachedResumeH5 from './h5'
import ResumeOnlineModal from '../ResumeOnline'
import UploadResumeModal from '../UploadFile'
import { useMainResumeContext } from '../Main/context'
import { RouterContext } from '../routerProvider'

const AttachedResume = (props: { lang: any; config: any }) => {
  const { lang } = props
  const [openUploadModal, setOpenUploadModal] = useState(false)
  const [openOnlineModal, setOpenOnlineModal] = useState(false)
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  const { push } = useContext(RouterContext)
  const pathname = usePathname()
  const isMobile = isMobileDevice()
  const chatProcess = isChatProcess()
  const profile = lang.profile || {}
  const { setUserDetail, initData } = useAttachedResumeContext()
  const schoolList = useSchool()

  useEffect(() => {
    if (userDetail?.id) {
      setUserDetail(userDetail)
    }
  }, [userDetail])

  const handleBack = () => {
    const searchWords = getUrlSearchString(2)
    push(`${pathname}${searchWords}`)
  }

  const openUploadResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_upload' : 'new_user_complete_resume_upload')
    setOpenUploadModal(true)
  }

  const openOnlineResumeModal = () => {
    addEventTag(chatProcess ? 'new_user_chat_resume_fill' : 'new_user_complete_resume_fill')
    Promise.resolve(initData(userDetail)).then(() => {
      setOpenOnlineModal(true)
    })
  }

  const handleUploadResume = () => {
    setOpenUploadModal(false)
  }

  const handleOnlineResume = () => {
    setOpenOnlineModal(false)
  }

  if (isMobile) {
    return <AttachedResumeH5 {...props} schoolList={schoolList} />
  }

  return (
    <AttachedResumeWrap>
      <div className='attachedResume'>
        <h1>
          {profile.steps?.text2}{' '}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='10'
            height='9'
            viewBox='0 0 10 9'
            fill='none'
          >
            <circle cx='5' cy='4.5' r='4.5' fill='#3CEAF6' />
          </svg>
        </h1>
        <h2>{profile.attachedResume?.title}</h2>
        <Options>
          <Option onClick={openUploadResumeModal}>
            <div className='textContainer'>
              <p className='optionTitle'>{profile.attachedResume?.upload}</p>
              <p className='optionSubtitle'>{profile.attachedResume?.uploadTip}</p>
            </div>

            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
              className={'hoverSvg'}
            >
              <path
                d='M27.3771 4.61913C25.4246 2.6665 22.2818 2.6665 15.9965 2.6665C9.71107 2.6665 6.56837 2.6665 4.61576 4.61913C3.60577 5.6291 3.1182 6.95749 2.88281 8.87448C3.59037 8.08693 4.44055 7.43088 5.39521 6.94445C6.43719 6.41353 7.55292 6.19824 8.78828 6.0973C9.98179 5.9998 11.4503 5.99981 13.246 5.99984H18.7469C20.5426 5.99981 22.0111 5.9998 23.2046 6.0973C24.4401 6.19824 25.5558 6.41353 26.5977 6.94445C27.5523 7.43088 28.4026 8.08693 29.1101 8.87448C28.8747 6.95749 28.3871 5.6291 27.3771 4.61913Z'
                fill='black'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.66406 18.6664C2.66406 14.9327 2.66406 13.0659 3.39069 11.6398C4.02984 10.3854 5.04972 9.36553 6.30413 8.72638C7.73021 7.99976 9.59705 7.99976 13.3307 7.99976H18.6641C22.3978 7.99976 24.2646 7.99976 25.6907 8.72638C26.9451 9.36553 27.965 10.3854 28.6041 11.6398C29.3307 13.0659 29.3307 14.9327 29.3307 18.6664C29.3307 22.4002 29.3307 24.267 28.6041 25.6931C27.965 26.9475 26.9451 27.9674 25.6907 28.6064C24.2646 29.3331 22.3978 29.3331 18.6641 29.3331H13.3307C9.59705 29.3331 7.73021 29.3331 6.30413 28.6064C5.04972 27.9674 4.02984 26.9475 3.39069 25.6931C2.66406 24.267 2.66406 22.4002 2.66406 18.6664ZM16.7045 13.9594C16.517 13.7718 16.2626 13.6664 15.9974 13.6664C15.7322 13.6664 15.4778 13.7718 15.2903 13.9594L11.957 17.2927C11.5664 17.6832 11.5664 18.3163 11.957 18.7068C12.3475 19.0974 12.9806 19.0974 13.3711 18.7068L14.9974 17.0807V22.6664C14.9974 23.2187 15.4451 23.6664 15.9974 23.6664C16.5497 23.6664 16.9974 23.2187 16.9974 22.6664V17.0807L18.6237 18.7068C19.0142 19.0974 19.6473 19.0974 20.0378 18.7068C20.4283 18.3163 20.4283 17.6832 20.0378 17.2927L16.7045 13.9594Z'
                fill='#B6B6B6'
              />
            </svg>
          </Option>
          <Option onClick={openOnlineResumeModal}>
            <div className='textContainer'>
              <p className='optionTitle'>{profile.attachedResume?.online}</p>
              <p className='optionSubtitle'>{profile.attachedResume?.onlineTip}</p>
            </div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32'
              height='32'
              viewBox='0 0 32 32'
              fill='none'
            >
              <path
                d='M20.7313 2.08127C21.3627 1.4498 22.3867 1.4498 23.0181 2.08127L26.8224 5.88556C27.4538 6.51703 27.4538 7.54086 26.8224 8.17234L25.4918 9.50277C25.1761 9.81851 24.6642 9.81851 24.3485 9.50277L19.4008 4.55511C19.0851 4.23937 19.0851 3.72746 19.4008 3.41171L20.7313 2.08127Z'
                fill='#B6B6B6'
              />
              <path
                d='M16.7018 5.78562C17.3332 5.15414 18.357 5.15414 18.9886 5.78562L22.7928 9.58989C23.4244 10.2214 23.4244 11.2452 22.7928 11.8767L10.8533 23.8162C10.7901 23.8794 10.6877 23.8794 10.6246 23.8162L4.76221 17.9539C4.69905 17.8907 4.69905 17.7883 4.76221 17.7253L16.7018 5.78562Z'
                fill='#B6B6B6'
              />
              <path
                d='M3.58746 19.1747C3.60631 19.0427 3.76766 18.9889 3.86188 19.0832L9.49558 24.7169C9.58981 24.8111 9.53603 24.9724 9.40411 24.9914L4.75236 25.6558C3.6852 25.8083 2.77048 24.8936 2.92293 23.8265L3.58746 19.1747Z'
                fill='#B6B6B6'
              />
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.88672 27.9911C3.88672 27.3838 4.37911 26.8914 4.98649 26.8914H26.0075C26.6149 26.8914 27.1073 27.3838 27.1073 27.9911C27.1073 28.5985 26.6149 29.0909 26.0075 29.0909H4.98649C4.37911 29.0909 3.88672 28.5985 3.88672 27.9911Z'
                fill='black'
              />
            </svg>
          </Option>
        </Options>
        <BackButton onClick={handleBack}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            viewBox='0 0 18 18'
            fill='none'
          >
            <path
              d='M5.25 13.5L1.5 9L5.25 4.5'
              stroke='#121212'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>{' '}
          <p className='countdownText'>{profile.back}</p>
        </BackButton>
      </div>
      <SafeInfo lang={lang} />

      {openOnlineModal && (
        <ResumeOnlineModal
          {...props}
          userDetail={userDetail}
          getUserInfo={getUserInfo}
          open={openOnlineModal}
          onOk={handleOnlineResume}
          onCancel={() => setOpenOnlineModal(false)}
          schoolList={schoolList}
        />
      )}

      {openUploadModal && (
        <UploadResumeModal
          {...props}
          userDetail={userDetail}
          getUserInfo={getUserInfo}
          open={openUploadModal}
          onOk={handleUploadResume}
          onCancel={() => setOpenUploadModal(false)}
        />
      )}
    </AttachedResumeWrap>
  )
}

const AttachedResumeWrap = styled.div`
  display: flex;
  width: 700px;
  margin: 70px auto 0 auto;
  flex-direction: column;

  .profileStep {
    margin-bottom: 24px;
    padding-left: 48px;
  }

  .attachedResume {
    min-height: 600px;
    padding: 40px 32px;
    border-radius: 16px;
    margin-bottom: 40px;
    background: #fff;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    color: #000;
    margin-bottom: 12px;
    text-align: center;
  }
  h2 {
    color: #3b3c3c;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
  }
  p.subtitle {
    color: #515151;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  svg.resumeIcon {
    width: 40px;
    height: 40px;
    margin-bottom: 32px;
  }
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 56px;
`

const Option = styled.div`
  border-radius: 16px;
  background: #f8f9fa;
  display: flex;
  padding: 32px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
  flex: 1 0 0;
  align-self: stretch;
  &:hover {
    background: #f7faff;
    cursor: pointer;
    svg path {
      fill: #1c70ee;
      &:last-child {
        fill: #000;
      }
    }
    .hoverSvg {
      path {
        fill: #1c70ee !important;
        &:first-of-type {
          fill: #000 !important;
        }
      }
    }
  }

  .textContainer {
    flex-grow: 1;
    max-width: calc(100% - 40px);
  }

  .icon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .optionTitle {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
  }

  .optionSubtitle {
    text-align: center;
    margin-top: 4px;
    color: #3b3c3c;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`

const BackButton = styled.div`
  display: inline-flex;
  height: 44px;
  padding: 10px 10px 10px 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  p.countdownText {
    color: #111212;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`

export default withAttachedResumeProvider(AttachedResume)
