export const AvatarIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={41} height={40} fill='none' {...props}>
    <path
      stroke='#FFC53D'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3.333}
      d='m20.14 31.311 3.96-2.798-3.96-10.218-3.962 10.218 3.961 2.798Z'
    />
    <path
      stroke='#2378E5'
      strokeWidth={3.333}
      d='M35.445 34.392c0-8.453-6.853-15.306-15.306-15.306S4.833 25.939 4.833 34.392'
    />
    <path
      stroke='#2378E5'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3.333}
      d='M20.14 18.182a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z'
    />
  </svg>
)

export const InfoIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={17} height={16} fill='none' {...props}>
    <path
      fill='#646566'
      d='M8.499 13.333a5.333 5.333 0 1 1 0-10.667 5.333 5.333 0 0 1 0 10.667Zm0 1.334a6.667 6.667 0 1 0 0-13.334 6.667 6.667 0 0 0 0 13.334ZM9.166 4.31v5.333H7.833V4.31h1.333Zm0 7.69v-1.333H7.831V12h1.333Z'
    />
  </svg>
)

export const UploadIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={27} height={27} fill='none' {...props}>
    <rect width={26.667} height={26.667} x={0.332} y={0.333} fill='#000' rx={13.333} />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M7.623 19.237h12.088a1.06 1.06 0 0 0 1.06-1.06v-7.425a1.06 1.06 0 0 0-1.06-1.06h-2.122L16.53 8.1h-5.724l-1.06 1.591H7.622a1.06 1.06 0 0 0-1.06 1.06v7.425a1.06 1.06 0 0 0 1.06 1.06Zm4.387-5.113a1.657 1.657 0 1 1 3.314 0 1.657 1.657 0 0 1-3.314 0Zm1.657-3.116a3.116 3.116 0 1 0 0 6.232 3.116 3.116 0 0 0 0-6.232Z'
      clipRule='evenodd'
    />
  </svg>
)

export const HelperIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={14} height={14} fill='none' {...props}>
    <path
      fill='#B8B8B8'
      d='m5.808 6.124-.558-.02-.558-.02v-.012a1.529 1.529 0 0 1 .009-.099 2.922 2.922 0 0 1 .282-.994c.137-.279.35-.58.677-.811.333-.236.754-.377 1.265-.377 1.04 0 1.73.57 1.97 1.335a2.086 2.086 0 0 1-.541 2.104h-.001c-.437.415-.688.65-.83.781v.738H6.405v-.986c0-.168.076-.327.205-.433l.01-.008.12-.109c.132-.122.38-.354.843-.792a.97.97 0 0 0 .245-.962c-.087-.278-.33-.552-.904-.552-.296 0-.488.078-.62.172a1.035 1.035 0 0 0-.322.395 1.808 1.808 0 0 0-.175.647v.006-.003ZM7.584 10.5V9.332H6.417v1.166h1.167Z'
    />
    <path
      fill='#B8B8B8'
      d='M7 12.833A5.833 5.833 0 1 0 7 1.166a5.833 5.833 0 0 0 0 11.667Zm0-1.167a4.667 4.667 0 1 1 0-9.333 4.667 4.667 0 0 1 0 9.333Z'
    />
  </svg>
)

export const ArrowIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.084 4.86128C14.3444 5.12163 14.3444 5.54374 14.084 5.80409L8.7507 11.1374C8.49035 11.3978 8.06824 11.3978 7.80789 11.1374L2.47456 5.80409C2.21421 5.54374 2.21421 5.12163 2.47456 4.86128C2.73491 4.60093 3.15702 4.60093 3.41737 4.86128L8.2793 9.72321L13.1412 4.86128C13.4016 4.60093 13.8237 4.60093 14.084 4.86128Z'
        fill='#515151'
      />
    </svg>
  )
}
