import React from 'react'

export const LanguageIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
    <g fill='#000' fillRule='evenodd' clipPath='url(#a)' clipRule='evenodd'>
      <path d='M10 17.917a7.917 7.917 0 1 0 0-15.834 7.917 7.917 0 0 0 0 15.834Zm0 1.583a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19Z' />
      <path d='M18.71 10.634H1.293V9.367H18.71v1.267Z' />
      <path d='M6.2 17.917V2.083h1.266v15.834H6.199ZM12.535 17.917V2.083h1.267v15.834h-1.267Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h20v20H0z' />
      </clipPath>
    </defs>
  </svg>
)

export const LogoIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48} height={48} fill='none' {...props}>
    <rect width={48} height={48} fill='#1c70ee' rx={10.971} />
    <path
      fill='#FFC53D'
      d='M9.742 22.302c0 1.127.826 1.96 1.963 1.96a1.92 1.92 0 0 0 1.826-1.202c.097-.24.143-.498.137-.758 0-1.176-.846-1.96-1.963-1.96-1.137 0-1.963.784-1.963 1.96Z'
    />
    <path
      fill='#fff'
      d='M22.231 18.849c2.35 0 3.768-1.726 3.768-3.732 0-2.008-1.42-3.708-3.768-3.708s-3.741 1.7-3.741 3.707c0 2.008 1.42 3.733 3.741 3.733Zm0-1.674c-1.16 0-1.806-.952-1.806-2.059 0-1.106.645-2.06 1.806-2.06 1.188 0 1.832.954 1.832 2.06 0 1.107-.644 2.058-1.832 2.058v.001Zm8.117 1.674c1.961 0 3.097-.953 3.097-2.265 0-2.858-4.23-1.983-4.23-3.012 0-.386.437-.695 1.109-.695.877 0 1.678.386 2.116.824l.752-1.314a4.596 4.596 0 0 0-2.87-.978c-1.88 0-2.915 1.033-2.915 2.24 0 2.78 4.207 1.828 4.207 2.985 0 .439-.387.751-1.187.751-.877 0-1.936-.489-2.452-.978l-.802 1.33c.775.7 1.962 1.112 3.175 1.112Zm7.453 0c1.961 0 3.097-.953 3.097-2.265 0-2.858-4.23-1.983-4.23-3.012 0-.386.438-.695 1.11-.695.877 0 1.677.386 2.115.824l.752-1.314a4.597 4.597 0 0 0-2.864-.979c-1.885 0-2.915 1.033-2.915 2.24 0 2.78 4.206 1.828 4.206 2.985 0 .439-.387.751-1.187.751-.877 0-1.936-.489-2.452-.978l-.806 1.331c.749.7 1.935 1.112 3.174 1.112Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M10.11 8.203h1.889v3.252h1.356a3.716 3.716 0 0 1 3.429 2.287 3.7 3.7 0 0 1-.805 4.038 3.713 3.713 0 0 1-2.624 1.085H10.11V8.204Zm1.888 8.449v.574h1.079a2.1 2.1 0 0 0 2.1-2.097 2.095 2.095 0 0 0-2.1-2.097h-1.078v3.62Z'
      clipRule='evenodd'
    />
    <path
      fill='#fff'
      d='M9.25 39.797c-.982 0-1.645-.161-2.148-.345l.594-2.507c.338.12.693.181 1.052.184 1.096 0 1.417-.735 1.417-2.183v-9.273h3.36v9.184c0 2.758-1.052 4.94-4.274 4.94Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M21.365 38.133c4.007 0 6.429-2.948 6.429-6.379 0-3.43-2.424-6.335-6.43-6.335-4.004 0-6.386 2.906-6.386 6.335 0 3.43 2.422 6.38 6.387 6.38Zm0-2.86c-1.982 0-3.084-1.627-3.084-3.52 0-1.892 1.1-3.52 3.084-3.52 2.024 0 3.126 1.629 3.126 3.52 0 1.892-1.102 3.52-3.126 3.52ZM29.252 20.297h3.15v5.431h2.262a6.186 6.186 0 0 1 6.188 6.19 6.19 6.19 0 0 1-6.188 6.188h-5.412v-17.81Zm3.15 14.112v.96H34.2l-.001-.002a3.499 3.499 0 0 0 3.501-3.503 3.503 3.503 0 0 0-3.501-3.502h-1.796v6.047H32.4Z'
      clipRule='evenodd'
    />
  </svg>
)

export const LogoIconMobile = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={40} height={40} fill='none' {...props}>
    <rect width={40} height={40} fill='#1c70ee' rx={9.143} />
    <path
      fill='#FFC53D'
      d='M8.12 18.585c0 .939.687 1.634 1.635 1.634a1.6 1.6 0 0 0 1.522-1.003c.08-.2.119-.415.114-.63 0-.98-.705-1.634-1.636-1.634-.948 0-1.636.653-1.636 1.633Z'
    />
    <path
      fill='#fff'
      d='M18.527 15.707c1.959 0 3.14-1.437 3.14-3.11 0-1.672-1.183-3.09-3.14-3.09s-3.118 1.417-3.118 3.09c0 1.673 1.183 3.11 3.118 3.11Zm0-1.394c-.967 0-1.505-.794-1.505-1.716 0-.922.538-1.716 1.505-1.716.99 0 1.527.794 1.527 1.716 0 .922-.537 1.715-1.527 1.715Zm6.764 1.394c1.635 0 2.581-.794 2.581-1.887 0-2.382-3.525-1.652-3.525-2.51 0-.322.365-.58.924-.58.731 0 1.398.323 1.764.687l.626-1.095a3.83 3.83 0 0 0-2.392-.814c-1.566 0-2.428.86-2.428 1.866 0 2.317 3.506 1.523 3.506 2.488 0 .365-.323.626-.99.626-.73 0-1.613-.408-2.043-.815l-.668 1.108c.645.583 1.635.926 2.645.926Zm6.211 0c1.634 0 2.58-.794 2.58-1.887 0-2.382-3.524-1.652-3.524-2.51 0-.322.365-.58.925-.58.73 0 1.397.323 1.762.687l.627-1.095a3.831 3.831 0 0 0-2.387-.815c-1.57 0-2.429.86-2.429 1.867 0 2.316 3.506 1.523 3.506 2.487 0 .365-.323.626-.99.626-.73 0-1.613-.408-2.043-.815l-.672 1.11c.624.582 1.613.925 2.645.925Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M8.426 6.836H10v2.71h1.13a3.096 3.096 0 0 1 2.858 1.906 3.084 3.084 0 0 1-.67 3.365c-.58.579-1.367.904-2.187.904H8.426V6.836ZM10 13.876v.48h.899v-.002a1.75 1.75 0 0 0 1.75-1.747 1.746 1.746 0 0 0-1.75-1.747H10v3.017Z'
      clipRule='evenodd'
    />
    <path
      fill='#fff'
      d='M7.709 33.164c-.82 0-1.372-.134-1.791-.288l.495-2.088c.282.099.578.15.877.153.914 0 1.181-.613 1.181-1.82v-7.727h2.8v7.654c0 2.298-.877 4.116-3.562 4.116Z'
    />
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M17.804 31.778c3.34 0 5.358-2.457 5.358-5.316 0-2.86-2.02-5.28-5.358-5.28-3.338 0-5.322 2.422-5.322 5.28s2.018 5.316 5.322 5.316Zm0-2.384c-1.652 0-2.57-1.356-2.57-2.933 0-1.577.917-2.933 2.57-2.933 1.687 0 2.605 1.357 2.605 2.933 0 1.576-.918 2.933-2.605 2.933ZM24.377 16.914h2.625v4.526h1.885a5.155 5.155 0 0 1 5.156 5.158 5.159 5.159 0 0 1-5.156 5.157h-4.51v-14.84ZM27 28.674v.8H28.5v-.002a2.917 2.917 0 0 0 2.062-4.982 2.917 2.917 0 0 0-2.063-.855h-1.497v5.04Z'
      clipRule='evenodd'
    />
  </svg>
)

export const LogoTextIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={96} height={24} fill='none' {...props}>
    <path
      fill='#1c70ee'
      d='M59.61 23.95c-1.15 0-1.924-.19-2.512-.405l.695-2.942c.395.14.81.213 1.229.216 1.282 0 1.657-.863 1.657-2.562V6.934h3.928v11.219c0 3.237-1.23 5.797-4.997 5.797Z'
    />
    <path
      fill='#1c70ee'
      fillRule='evenodd'
      d='M73.552 21.997c4.684 0 7.515-3.46 7.515-7.486 0-4.027-2.833-7.435-7.515-7.435-4.682 0-7.466 3.41-7.466 7.435 0 4.024 2.831 7.486 7.466 7.486Zm0-3.356c-2.318 0-3.605-1.91-3.605-4.131s1.286-4.13 3.605-4.13c2.365 0 3.654 1.91 3.654 4.13 0 2.22-1.289 4.13-3.654 4.13ZM82.441.955h3.683v6.484h2.644a7.205 7.205 0 0 1 5.114 2.127 7.263 7.263 0 0 1 2.119 5.136 7.279 7.279 0 0 1-2.119 5.136 7.217 7.217 0 0 1-5.114 2.128h-6.327V.955Zm3.682 16.672v1.126h2.102l-.001-.003a4.074 4.074 0 0 0 2.894-1.203 4.115 4.115 0 0 0 1.199-2.907 4.12 4.12 0 0 0-1.199-2.906 4.084 4.084 0 0 0-2.894-1.204h-2.1v7.097Z'
      clipRule='evenodd'
    />
    <path
      fill='#1c70ee'
      d='M22.437 22.047c4.605 0 7.383-3.401 7.383-7.357 0-3.955-2.782-7.306-7.383-7.306-4.602 0-7.332 3.349-7.332 7.306 0 3.958 2.782 7.357 7.332 7.357Zm0-3.3c-2.275 0-3.539-1.875-3.539-4.057 0-2.181 1.264-4.059 3.539-4.059 2.326 0 3.59 1.878 3.59 4.06 0 2.18-1.264 4.055-3.59 4.055v.002Zm14.663 3.3c3.843 0 6.068-1.878 6.068-4.465 0-5.632-8.29-3.908-8.29-5.935 0-.763.859-1.371 2.174-1.371 1.719 0 3.288.762 4.147 1.624l1.474-2.59a8.972 8.972 0 0 0-5.624-1.926c-3.684 0-5.71 2.035-5.71 4.415 0 5.478 8.242 3.602 8.242 5.883 0 .864-.759 1.48-2.326 1.48-1.719 0-3.793-.964-4.804-1.928l-1.571 2.622c1.517 1.378 3.844 2.19 6.22 2.19Zm13.271 0c3.842 0 6.067-1.878 6.067-4.465 0-5.632-8.288-3.908-8.288-5.935 0-.763.86-1.371 2.175-1.371 1.718 0 3.286.762 4.144 1.624l1.474-2.59a8.975 8.975 0 0 0-5.613-1.928c-3.693 0-5.71 2.035-5.71 4.415 0 5.478 8.241 3.602 8.241 5.883 0 .864-.759 1.48-2.326 1.48-1.719 0-3.793-.964-4.804-1.928l-1.579 2.624c1.466 1.378 3.791 2.19 6.219 2.19Z'
    />
    <path
      fill='#1c70ee'
      fillRule='evenodd'
      d='M0 .955h3.702v6.519H6.36a7.243 7.243 0 0 1 5.142 2.139 7.304 7.304 0 0 1 2.13 5.164 7.319 7.319 0 0 1-2.13 5.163 7.255 7.255 0 0 1-5.142 2.14H0V.954Zm3.701 16.762v1.132h2.113v-.003a4.097 4.097 0 0 0 2.909-1.21 4.132 4.132 0 0 0 1.205-2.922 4.142 4.142 0 0 0-1.205-2.922 4.105 4.105 0 0 0-2.91-1.21h-2.11v7.135H3.7Z'
      clipRule='evenodd'
    />
    <path
      fill='#FFC53D'
      d='M60.188 3.309c0 1.322.964 2.3 2.294 2.3a2.235 2.235 0 0 0 2.135-1.412 2.25 2.25 0 0 0 .16-.888c0-1.38-.99-2.3-2.295-2.3-1.33 0-2.294.92-2.294 2.3Z'
    />
  </svg>
)

export const BackIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} fill='none' {...props}>
    <path
      stroke='#121212'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.5}
      d='M5.25 13.5 1.5 9l3.75-4.5'
    />
  </svg>
)
