export const ArrowIcon = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
      <path
        fill='#000'
        fillRule='evenodd'
        d='m7.856 9.311-4.32-4.32a.667.667 0 0 0-.943 0l-.141.142a.667.667 0 0 0 0 .943l5.404 5.404 5.404-5.404a.667.667 0 0 0 0-.943l-.142-.141a.667.667 0 0 0-.942 0l-4.32 4.32Z'
        clipRule='evenodd'
      />
    </svg>
  )
}
