import { Slider } from 'antd'
import { isMobileDevice } from 'helpers/utilities'
import React, { useRef, useState } from 'react'
import * as AvatarEditor from 'react-avatar-editor'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import styles from './index.module.scss'

type componentProps = {
  src: any
  scale?: number
  rotate?: number
  // eslint-disable-next-line no-unused-vars
  callBackBlobFile: (file: any) => void
  aspect?: number
  locked?: boolean
  cropWidth?: number
  cropHeight?: number
}

const ImageCrop = ({
  src,
  scale,
  rotate,
  callBackBlobFile,
  cropWidth,
  cropHeight
}: componentProps) => {
  const editor = useRef(null)
  const readyCanvas = useRef(false)

  const getImageUrl = async () => {
    if (!readyCanvas.current) return
    const dataUrl = (editor?.current as any).getImageScaledToCanvas().toDataURL()
    const result = await fetch(dataUrl)
    const blob = await result.blob()
    const url = URL.createObjectURL(blob)
    const newFile = new File([blob], src?.name, { type: src?.type })
    return callBackBlobFile({ url, blob: newFile })
  }
  return (
    <>
      <AvatarEditor
        ref={editor}
        image={src}
        width={cropWidth || 200}
        height={cropHeight || 200}
        border={20}
        borderRadius={125}
        color={[0, 0, 0, 0.3]} // RGBA
        scale={scale}
        rotate={rotate}
        onLoadSuccess={() => {
          getImageUrl()
        }}
        onImageChange={() => {
          getImageUrl()
        }}
        onImageReady={() => {
          readyCanvas.current = true
          getImageUrl()
        }}
      />
    </>
  )
}

export default ImageCrop

export const CropImg = ({ previewImagePath, onCancel, onConfirm, lang }) => {
  const [scale, setScale] = useState<number>(1)
  const [rotate, setRotate] = useState<number>(0)
  const [fileBlob, setFileBlob] = useState<{ [key: string]: any } | undefined>()
  const isMobile = isMobileDevice()
  const wh = isMobile ? { cropWidth: 200, cropHeight: 200 } : {}
  const profile = lang.profile || {}

  return (
    <div className={styles.cropAvatar}>
      <div className={styles.cropAvatar_header}>
        <p className={styles.cropAvatar_title} onClick={onCancel}>
          {profile.avatarModal?.editTitle}
        </p>
      </div>
      <div className={styles.cropAvatar_content}>
        <ImageCrop
          src={previewImagePath}
          callBackBlobFile={setFileBlob}
          scale={scale}
          rotate={rotate}
          {...wh}
        />
      </div>
      <div className={styles.cropAvatar_action}>
        <div className={styles.cropAvatar_label}>
          <span className={styles.cropAvatar_item} style={{ paddingBottom: '10px' }}>
            {profile.avatarModal?.zoom}:
          </span>
          <span className={styles.cropAvatar_item}>{profile.avatarModal?.direction}:</span>
        </div>
        <div className={styles.cropAvatar_slider}>
          <div className={styles.cropAvatar_item}>
            <Slider value={scale} max={3} min={0.1} step={0.1} onChange={(ev) => setScale(ev)} />
          </div>
          <div className={styles.cropAvatar_item}>
            <Slider value={rotate} max={180} min={0} step={1} onChange={(ev) => setRotate(ev)} />
          </div>
        </div>
      </div>
      <div className={styles.cropAvatar_footer}>
        <Button variant='whiteBoxShadowBtn' btnSize='size44' onClick={onCancel}>
          {profile.cancel}
        </Button>
        <Button
          disabled={!fileBlob}
          onClick={() => {
            onConfirm(fileBlob?.blob)
          }}
          btnSize='size44'
        >
          {profile.apply}
        </Button>
      </div>
    </div>
  )
}

const BackIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={24} height={25} fill='none' {...props}>
    <path
      fill='#121212'
      fillRule='evenodd'
      d='M16.707 21.207a1 1 0 0 1-1.414 0l-8-8a1 1 0 0 1 0-1.414l8-8a1 1 0 1 1 1.414 1.414L9.414 12.5l7.293 7.293a1 1 0 0 1 0 1.414Z'
      clipRule='evenodd'
    />
  </svg>
)
