import { key } from "localforage"

export function formatNationality(arr) {
  let result = []
  if (!Array.isArray(arr)) return result
  if (arr.length == 1) return arr
  arr.forEach((ele) => {
    const item = ele.nationalities
    if (item) {
      result.push(...item)
    }
  })

  result = result.map((item) => {
    const _val = item.value
    return {
      ...item,
      value: item.id,
      label: _val,
      key: item.id
    }
  })

  return result
}
